import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import PageHeading from "~/components/styled/page-heading"
import CompanyList from "~/components/company-list"
import SEO from "~/components/seo"

const CompanyTypePage = ({ data }) => {
  const companies = data.strapiCompanyType.company_types
  const seo = {
    title: data.strapiCompanyType.name,
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <div>
        <PageHeading>{data.strapiCompanyType.name}</PageHeading>
        <CompanyList companies={companies} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CompanyTypesQuery($slug: String!) {
    strapiCompanyType(slug: { eq: $slug }) {
      name
      company_types {
        id
        slug
        title
        description
        url
      }
    }
  }
`

export default CompanyTypePage
